import React, { FC } from "react";
import styled, { css } from "styled-components";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Assets
import Instagram from "./../images/svg/instagram.svg";
import Facebook from "./../images/svg/facebook.svg";
import Map from "./../images/svg/map-pointer.svg";

// Styles
const SocialButton = css`
  height: 30px;
  transition: all 0.125s !important;
  width: 30px;

  @media (min-width: 768px) {
    height: 32px;
    width: 32px;
  }

  &:hover {
    fill: var(--terracotta);
  }
`;

const InstagramIcon = styled((props) => <Instagram {...props} />)`
  ${SocialButton}
`;

const FacebookIcon = styled((props) => <Facebook {...props} />)`
  ${SocialButton}
`;

const MapIcon = styled((props) => <Map {...props} />)`
  ${SocialButton}
`;

interface IProps {
  img?: any;
  alt?: string;
  heading: string;
  time?: string;
  distance?: string;
  description?: string;
  facebook?: string;
  instagram?: string;
  map?: string;
}

const CardHorizontal: FC<IProps> = ({
  img,
  alt,
  heading,
  time,
  distance,
  description,
  facebook,
  instagram,
  map,
}) => {
  const { t } = useTranslation();

  return (
    <div className="card mb-3">
      <Row className="align-items-center g-0">
        <Col md={3} xl={2}>
          <div className="d-flex justify-content-center p-3">
            <img src={img} className="img-fluid rounded" alt={alt} />
          </div>
        </Col>
        <Col md={9} xl={10} className="d-flex align-items-center">
          <div className="card-body">
            <div className="d-flex flex-column flex-md-row">
              <h4 className="card-title d-inline-block me-0 me-md-2 lh-base">
                {heading}
              </h4>
              <span className="mb-2 mb-md-0">
                {typeof time !== "undefined" || typeof distance !== "undefined"
                  ? "("
                  : ""}
                {typeof time !== "undefined" ? `${time} / ` : ""}
                {typeof distance !== "undefined" && distance}
                {typeof time !== "undefined" || typeof distance !== "undefined"
                  ? ")"
                  : ""}
              </span>
            </div>
            {description !== "undefined" && (
              <p className="card-text">{description}</p>
            )}
            <div>
              {instagram && (
                <a
                  href={instagram}
                  target="_blank"
                  className="muted-link social"
                >
                  {t(`followInstagram`)}
                  <InstagramIcon />
                </a>
              )}
              {facebook && (
                <a
                  href={facebook}
                  target="_blank"
                  className="muted-link social"
                >
                  {t(`followFacebook`)}
                  <FacebookIcon />
                </a>
              )}
              {map && (
                <a href={map} target="_blank" className="muted-link social">
                  {t(`followFacebook`)}
                  <MapIcon />
                </a>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CardHorizontal;
