import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Components
import AppProvider from "./../components/appProvider";
import Layout from "./../components/layout";
import SEO from "./../components/seo";
import FadeInWhenVisible from "./../components/fadeInWhenVisible";
import Spacer from "./../components/spacer";
import CardHorizontal from "./../components/cardHorizontal";

// Constants
import {
  mapFoodAndDrinks,
  mapOcio,
  mapSupermarket,
} from "./../modules/constants/index";

// Types
import { ICardHorizontalProps } from "./../modules/interfaces";

const InfoPage = ({ data, location }: any) => {
  const { t } = useTranslation();
  const { site } = data || [];

  const { siteUrl, phoneRef } = site.siteMetadata;

  const translateFoodAndDrinks: any = t("foodAndDrinks.list", {
    returnObjects: true,
  });

  const translateOcio: any = t("ocio.list", { returnObjects: true });

  const translatSupermarket: any = t("supermarket.list", {
    returnObjects: true,
  });

  console.log("translatSupermarket", translatSupermarket);

  return (
    <AppProvider location={location}>
      <Layout>
        <SEO />
        <Helmet>
          <script type="application/ld+json">
            {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "url": "${siteUrl}",
              "name": "${process.env.GATSBY_WEBSITE_NAME}",
              "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "${phoneRef}",
                "contactType": "Customer Support"
              }
            }
          `}
          </script>
        </Helmet>
        <section>
          <FadeInWhenVisible>
            <div className="bg-merino">
              <Container>
                <Row>
                  <Col sm={12}>
                    <Spacer>
                      <h1 className="mb-0">{t(`infoHeading`)}</h1>
                    </Spacer>
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col sm={12}>
                    <h3 className="mb-5">{t(`foodAndDrinks.heading`)}</h3>
                    <Spacer bottomOnly={true}>
                      {translateFoodAndDrinks.map(
                        (item: ICardHorizontalProps, index: number) => {
                          const { t } = useTranslation();

                          return (
                            <CardHorizontal
                              key={index}
                              img={`${mapFoodAndDrinks[index].img}`}
                              alt={t(`${item.alt}`)}
                              heading={t(`${item.heading}`)}
                              time={t(`${item.time}`)}
                              distance={t(`${item.distance}`)}
                              description={t(`${item.description}`)}
                              facebook={`${mapFoodAndDrinks[index].facebook}`}
                              map={`${mapFoodAndDrinks[index].map}`}
                            />
                          );
                        }
                      )}
                    </Spacer>
                    <h3 className="mb-5">{t(`ocio.heading`)}</h3>
                    <Spacer bottomOnly={true}>
                      {translateOcio.map(
                        (item: ICardHorizontalProps, index: number) => {
                          const { t } = useTranslation();

                          return (
                            <CardHorizontal
                              key={index}
                              img={`${mapOcio[index].img}`}
                              alt={t(`${item.alt}`)}
                              heading={t(`${item.heading}`)}
                              time={t(`${item.time}`)}
                              distance={t(`${item.distance}`)}
                              description={t(`${item.description}`)}
                              facebook={`${mapOcio[index].facebook}`}
                              map={`${mapOcio[index].map}`}
                            />
                          );
                        }
                      )}
                    </Spacer>
                    <h3 className="mb-5">{t(`supermarket.heading`)}</h3>
                    <Spacer bottomOnly={true}>
                      {translatSupermarket.map(
                        (item: ICardHorizontalProps, index: number) => {
                          const { t } = useTranslation();

                          return (
                            <CardHorizontal
                              key={index}
                              img={`${mapSupermarket[index].img}`}
                              alt={t(`${item.alt}`)}
                              heading={t(`${item.heading}`)}
                              time={t(`${item.time}`)}
                              distance={t(`${item.distance}`)}
                              description={t(`${item.description}`)}
                              facebook={`${mapSupermarket[index].facebook}`}
                              map={`${mapSupermarket[index].map}`}
                            />
                          );
                        }
                      )}
                    </Spacer>
                  </Col>
                </Row>
              </Container>
            </div>
          </FadeInWhenVisible>
        </section>
      </Layout>
    </AppProvider>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        author
        siteUrl
        keywords
        image
        menuLinks {
          name
          anchor
          translate
        }
        phone
        phoneRef
        whatsapp
        messenger
        facebook
        instagram
      }
    }
  }
`;

export default InfoPage;
